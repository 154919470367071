.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    h1, h2, h3, h4, h5, h6 {
      color: black;
    }
    button {
      margin-top: 1em;
    }
}
